import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery-area',
  templateUrl: './gallery-area.component.html',
  styleUrls: ['./gallery-area.component.css']
})
export class GalleryAreaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
