<section class="section fact-counter-area pt-60 pb-20" data-bg-img="assets/images/fact_bg.jpg">
    <div class="container t-xs-center t-sm-left">
        <div class="row is-inline-block is-block-sm">
            
            <div class="col-md-3 col-sm-6">
                <div class="counter-wrapper">
                    <div class="counter-icon">
                        <i class="flaticon-constructor-with-hard-hat-protection-on-his-head"></i>
                    </div>
                    <div class="counter-number">
                        <h2 class="count-text" data-speed="2000" data-stop="44">0</h2>
                        <div class="line"></div>
                        <h6 class="counter-title t-uppercase">Workers Employed</h6>
                       </div>
                </div>
            </div>
            
            <div class="col-md-3 col-sm-6">
                <div class="counter-wrapper">
                    <div class="counter-icon">
                        <i class="flaticon-wall-of-bricks"></i>
                    </div>
                    <div class="counter-number">
                        <h2 class="count-text" data-speed="2000" data-stop="12">0</h2>
                        <div class="line"></div>
                        <h6 class="counter-title t-uppercase">Built Houses</h6>
                       </div>
                </div>
            </div>
            
            <div class="col-md-3 col-sm-6">
                <div class="counter-wrapper">
                    <div class="counter-icon">
                        <i class="flaticon-construction-vehicle-for-concrete-transportation"></i>
                    </div>
                    <div class="counter-number">
                        <h2 class="count-text" data-speed="2000" data-stop="14">0</h2>
                        <div class="line"></div>
                        <h6 class="counter-title t-uppercase">Running Project</h6>
                       </div>
                </div>
            </div>

            <div class="col-md-3 col-sm-6">
                <div class="counter-wrapper">
                    <div class="counter-icon">
                        <i class="fa fa-coffee"></i>
                    </div>
                    <div class="counter-number">
                        <h2 class="count-text" data-speed="2000" data-stop="150">0</h2>
                        <div class="line"></div>
                        <h6 class="counter-title t-uppercase">Cups Of Coffee</h6>
                       </div>
                </div>
            </div>
            
        </div>
    </div>
</section>