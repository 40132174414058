<section class="section latest-news-area ptb-60">
    <div class="container">
        <div class="row mb-30">
            <div class="col-lg-7 col-md-8 col-sm-10 col-xs-12 col-xs-center t-center mb-40">
                <h6 class="section-subtitle mb-10 t-uppercase color-mid">From Our Blog</h6>
                <h2 class="section-title mb-20 font-22 t-uppercase">Latest news</h2>
                <div class="line"></div>
            </div>
        </div>
        <div class="row row-md-cell">
            <div class="col-md-6 entry-details">
                <figure class="entry-img embed-responsive embed-responsive-16by9 col-absolute-cell" data-bg-img="assets/images/blog/news_01.jpg">
                </figure>
                <div class="entry-content">
                    <span class="entry-info is-block mb-5">
                           <span class="mr-10"><i class="fa fa-clock-o mr-5"></i> 21 Jan, 2106</span>
                           <span><i class="fa fa-comment-o mr-5"></i> 23 Comments</span>
                       </span>
                    <h2 class="entry-title h3 mb-10"><a href="blog_single.html">Karen Gould and Tom Gerlach Promoted to Executive Vice President of Turner</a></h2>
                </div>
            </div>
            <div class="col-md-6 entry-list">
                <div class="row row-rl-10 entry-list-item mb-20">
                    <div class="col-xs-5">
                        <figure class="entry-img">
                            <img src="assets/images/blog/news_02.jpg" alt="" />
                           </figure>
                    </div>
                    <div class="col-xs-7">
                        <div class="entry-content">
                            <h2 class="entry-title h6 mb-10"><a href="blog_single.html">Turner’s First Quarter Building Cost Index Reflects Strong Market Conditions.</a></h2>
                            <span class="entry-info color-dark is-block mb-15">
                                   <span class="mr-10"><i class="fa fa-clock-o mr-5"></i> 21 Jan, 2106</span>
                                   <span><i class="fa fa-comment-o mr-5"></i> 23 Comments</span>
                               </span>
                            <p class="color-mid">Pellentesque suscipit tincidunt suscipit. Nullam vestibulum...</p>
                        </div>
                    </div>
                </div>
                <div class="row row-rl-10 entry-list-item mb-20">
                    <div class="col-xs-5">
                        <figure class="entry-img">
                            <img src="assets/images/blog/news_03.jpg" alt="" />
                           </figure>
                    </div>
                    <div class="col-xs-7">
                        <div class="entry-content">
                            <h2 class="entry-title h6 mb-10"><a href="blog_single.html">Turner’s First Quarter Building Cost Index Reflects Strong Market Conditions.</a></h2>
                            <span class="entry-info color-dark is-block mb-15">
                                   <span class="mr-10"><i class="fa fa-clock-o mr-5"></i> 21 Jan, 2106</span>
                                   <span><i class="fa fa-comment-o mr-5"></i> 23 Comments</span>
                               </span>
                            <p class="color-mid">Pellentesque suscipit tincidunt suscipit. Nullam vestibulum...</p>
                        </div>
                    </div>
                </div>
                <div class="row row-rl-10 entry-list-item">
                    <div class="col-xs-5">
                        <figure class="entry-img">
                            <img src="assets/images/blog/news_04.jpg" alt="" />
                           </figure>
                    </div>
                    <div class="col-xs-7">
                        <div class="entry-content">
                            <h2 class="entry-title h6 mb-10"><a href="blog_single.html">Turner’s First Quarter Building Cost Index Reflects Strong Market Conditions.</a></h2>
                            <span class="entry-info color-dark is-block mb-15">
                                   <span class="mr-10"><i class="fa fa-clock-o mr-5"></i> 21 Jan, 2106</span>
                                   <span><i class="fa fa-comment-o mr-5"></i> 23 Comments</span>
                               </span>
                            <p class="color-mid">Pellentesque suscipit tincidunt suscipit. Nullam vestibulum...</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-40 t-center">
            <a href="blog-classic.html" class="btn btn-rounded">Visit Our Blog<i class="fa fa-long-arrow-right ml-10"></i></a>
        </div>
    </div>
</section>
