import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-secretarial-services',
  templateUrl: './secretarial-services.component.html',
  styleUrls: ['./secretarial-services.component.css']
})
export class SecretarialServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
