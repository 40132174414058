import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import {RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ServicesComponent } from './pages/services/services.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { FooterComponent } from './components/footer/footer.component';
import { LatestNewsComponent } from './components/latest-news/latest-news.component';
import { ContactBannerComponent } from './components/contact-banner/contact-banner.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { OurTeamComponent } from './components/our-team/our-team.component';
import { GalleryAreaComponent } from './components/gallery-area/gallery-area.component';
import { FactCounterComponent } from './components/fact-counter/fact-counter.component';
import { ServiceAreaComponent } from './components/service-area/service-area.component';
import { FeatureAreaComponent } from './components/feature-area/feature-area.component';
import { AboutSectionComponent } from './components/about-section/about-section.component';
import { ClientsSectionComponent } from './components/clients-section/clients-section.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { UsersService } from './services/users.service';
import { HttpClientModule } from '@angular/common/http';
import { ToastNoAnimationModule, ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { NotificationsService } from './services/notifications.service';
import { AccountingServicesComponent } from './pages/services/accounting-services.component';
import { BookKeepingComponent } from './pages/services/book-keeping.component';
import { TaxationServicesComponent } from './pages/services/taxation-services.component';
import { PayrollServicesComponent } from './pages/services/payroll-services.component';
import { CompensationFundComponent } from './pages/services/compensation-fund.component';
import { SecretarialServicesComponent } from './pages/services/secretarial-services.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    ServicesComponent,
    ProjectsComponent,
    FooterComponent,
    LatestNewsComponent,
    ContactBannerComponent,
    TestimonialsComponent,
    OurTeamComponent,
    GalleryAreaComponent,
    FactCounterComponent,
    ServiceAreaComponent,
    FeatureAreaComponent,
    AboutSectionComponent,
    ClientsSectionComponent,
    ComingSoonComponent,
    AccountingServicesComponent,
    BookKeepingComponent,
    TaxationServicesComponent,
    PayrollServicesComponent,
    CompensationFundComponent,
    SecretarialServicesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ToastNoAnimationModule, 
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 6000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    RouterModule.forRoot([
      { path: '', redirectTo:'home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent},
      { path: 'about-us', component: AboutComponent },
      { path: 'our-services', component: ServicesComponent },
      { path: 'our-projects', component: ProjectsComponent },
      { path: 'contact-us', component: ContactComponent },
      { path: 'our-services/accounting', component: AccountingServicesComponent },
      { path: 'our-services/bookkeeping', component: BookKeepingComponent },
      { path: 'our-services/payroll', component: PayrollServicesComponent },
      { path: 'our-services/taxation', component: TaxationServicesComponent },
      { path: 'our-services/compensation-fund', component: CompensationFundComponent },
      { path: 'our-services/secretarial', component: SecretarialServicesComponent },
      {
        path:'**', redirectTo:'home'
      }
    ])
  ],
  providers: [
    UsersService, NotificationsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
