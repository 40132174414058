<section class="section our-clients-area ptb-60">
    <div class="container">
        <div class="row mb-30">
            <div class="col-lg-7 col-md-8 col-sm-10 col-xs-12 col-xs-center t-center mb-40">
                <h6 class="section-subtitle mb-10 t-uppercase color-mid">Testimonials</h6>
                <h2 class="section-title mb-20 font-22 t-uppercase">our Awesome clients</h2>
                <div class="line"></div>
            </div>
        </div>
        <div class="row row-tb-15">
            <div class="col-md-6">
                <div class="brands-area row row-tb-10 row-rl-10">
                    <div class="col-xs-12 col-sm-6 col-md-4">
                        <div class="client">
                            <img src="assets/images/brands/01.png" alt="client">
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-4">
                        <div class="client">
                            <img src="assets/images/brands/02.png" alt="client">
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-4">
                        <div class="client">
                            <img src="assets/images/brands/03.png" alt="client">
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-4">
                        <div class="client">
                            <img src="assets/images/brands/04.png" alt="client">
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-4">
                        <div class="client">
                            <img src="assets/images/brands/05.png" alt="client">
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-4">
                        <div class="client">
                            <img src="assets/images/brands/06.png" alt="client">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="testimonials-area testimonials-area-1 owl-carousel"
                    data-loop="true"
                    data-autoplay="true"
                    data-autoplay-timeout="4000"
                    data-smart-speed="1000"
                    data-nav-speed="false"
                    data-dots="true"
                    data-nav="false"
                    data-items="1">
                    <div class="testimonial-panel">
                        <div class="testimonial-icon">
                            <i class="fa fa-quote-left"></i>
                        </div>
                        <div class="testimonial-body">
                            <p class="mb-20 font-14">Lorem ipsum dolor sit consctetur adipiscing elit. Integer lore quam, adipiscing condimentum tristique vel, eleifend sed turpis. Cursus arcu id euismod in elementum purus dolor sit consctetur adipiscing elit.</p>
                            <div class="testimonial-meta">
                                <div class="testimonial-img">
                                    <img src="assets/images/testimonial/01.jpg" alt="Testimonial Author">
                                </div>
                                <h5 class="ptb-5 t-uppercase color-theme">John Doe</h5>
                                <h6 class="mb-0 color-mid">Ceo & Founder</h6>
                            </div>
                        </div>
                    </div>
                    <div class="testimonial-panel">
                        <div class="testimonial-icon">
                            <i class="fa fa-quote-left"></i>
                        </div>
                        <div class="testimonial-body">
                            <p class="mb-20 font-14">Lorem ipsum dolor sit consctetur adipiscing elit. Integer lore quam, adipiscing condimentum tristique vel, eleifend sed turpis. Cursus arcu id euismod in elementum purus dolor sit consctetur adipiscing elit.</p>
                            <div class="testimonial-meta">
                                <div class="testimonial-img">
                                    <img src="assets/images/testimonial/02.jpg" alt="Testimonial Author">
                                </div>
                                <h5 class="ptb-5 t-uppercase color-theme">John Doe</h5>
                                <h6 class="mb-0 color-mid">Ceo & Founder</h6>
                            </div>
                        </div>
                    </div>
                    <div class="testimonial-panel">
                        <div class="testimonial-icon">
                            <i class="fa fa-quote-left"></i>
                        </div>
                        <div class="testimonial-body">
                            <p class="mb-20 font-14">Lorem ipsum dolor sit consctetur adipiscing elit. Integer lore quam, adipiscing condimentum tristique vel, eleifend sed turpis. Cursus arcu id euismod in elementum purus dolor sit consctetur adipiscing elit.</p>
                            <div class="testimonial-meta">
                                <div class="testimonial-img">
                                    <img src="assets/images/testimonial/03.jpg" alt="Testimonial Author">
                                </div>
                                <h5 class="ptb-5 t-uppercase color-theme">John Doe</h5>
                                <h6 class="mb-0 color-mid">Ceo & Founder</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>