import { Component, OnInit } from '@angular/core';
import { ContactRequestModel } from 'src/app/models/contactRequestModel';
import { NotificationsService } from 'src/app/services/notifications.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactModel: ContactRequestModel = { firstName: "", phone: "", message: "", email: "", subject: "", company: "", website: "", lastName: "" };
  constructor(private userService: UsersService, private notificationService: NotificationsService) { }
  ngOnInit() {
  }
  submitRequest() {
    console.log(this.contactModel);
    this.userService.contactUs(this.contactModel).subscribe(result => {
      if(result.includes('Email Sent Successfully')){
        this.notificationService.showSuccessMessage(result, "Email Sent");
        this.contactModel = { firstName: "", phone: "", message: "", email: "", subject: "", company: "", website: "", lastName: "" };
      }
      else {
        this.notificationService.showErrorMessage(result, "Unknown Error");
      }
      });
  }
}