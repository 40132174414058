    <!--Page Title-->
    <section class="page-title" style="background-image: url(assets/images/background/accounting4.jpg);background-size: cover;background-position-y: center;">
    	<div class="auto-container">
        	<h1>Our Successful Cases</h1>
            <ul class="page-breadcrumb">
            	<li><a [routerLink]="['/home']">Home</a></li>
                <li>Our Work</li>
            </ul>
        </div>
    </section>
    <!--End Page Title-->
    
    <!--Project Page Section-->
    <section class="project-page-section">
    	<div class="auto-container">
        	
            <!--MixitUp Galery-->
            <div class="mixitup-gallery">
                
                <!--Filter-->
                <div class="filters text-center clearfix">
                	
                	<ul class="filter-tabs filter-btns clearfix">
                        <li class="active filter" data-role="button" data-filter="all">Accounting</li>
                        <li class="filter" data-role="button" data-filter=".cumultation">Taxation</li>
                        <li class="filter" data-role="button" data-filter=".business">Payroll</li>
                        <li class="filter" data-role="button" data-filter=".management">Compensation Fund</li>
                        <li class="filter" data-role="button" data-filter=".commidities">Secretarial</li>
                    </ul>
                    
                </div>
                
                <div class="filter-list row clearfix">
					
                    <!--Gallery Item-->
                    <div class="gallery-item mix all col-lg-4 col-md-6 col-sm-12">
                        <div class="inner-box">
                            <div class="image">
                            	<img src="assets/images/gallery/15.jpg" alt="" />
                                <div class="overlay-box">
                                	<div class="content">
                                    	<ul>
                                        	<li><a href="project-detail.html">Business</a></li>
                                            <li><a href="project-detail.html">Corporate</a></li>
                                        </ul>
                                        <h3><a href="project-detail.html">Assets For Technology</a></h3>
                                    </div>
                                    <a class="read-more" href="project-detail.html">Read More <span class="icon flaticon-arrow-pointing-to-right"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!--Gallery Item-->
                    <div class="gallery-item mix all business management col-lg-4 col-md-6 col-sm-12">
                        <div class="inner-box">
                            <div class="image">
                            	<img src="assets/images/gallery/16.jpg" alt="" />
                                <div class="overlay-box">
                                	<div class="content">
                                    	<ul>
                                        	<li><a href="project-detail.html">Business</a></li>
                                            <li><a href="project-detail.html">Corporate</a></li>
                                        </ul>
                                        <h3><a href="project-detail.html">Assets For Technology</a></h3>
                                    </div>
                                    <a class="read-more" href="project-detail.html">Read More <span class="icon flaticon-arrow-pointing-to-right"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!--Gallery Item-->
                    <div class="gallery-item mix all commidities management col-lg-4 col-md-6 col-sm-12">
                        <div class="inner-box">
                            <div class="image">
                            	<img src="assets/images/gallery/17.jpg" alt="" />
                                <div class="overlay-box">
                                	<div class="content">
                                    	<ul>
                                        	<li><a href="project-detail.html">Business</a></li>
                                            <li><a href="project-detail.html">Corporate</a></li>
                                        </ul>
                                        <h3><a href="project-detail.html">Assets For Technology</a></h3>
                                    </div>
                                    <a class="read-more" href="project-detail.html">Read More <span class="icon flaticon-arrow-pointing-to-right"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!--Gallery Item-->
                    <div class="gallery-item mix all business col-lg-4 col-md-6 col-sm-12">
                        <div class="inner-box">
                            <div class="image">
                            	<img src="assets/images/gallery/18.jpg" alt="" />
                                <div class="overlay-box">
                                	<div class="content">
                                    	<ul>
                                        	<li><a href="project-detail.html">Business</a></li>
                                            <li><a href="project-detail.html">Corporate</a></li>
                                        </ul>
                                        <h3><a href="project-detail.html">Assets For Technology</a></h3>
                                    </div>
                                    <a class="read-more" href="project-detail.html">Read More <span class="icon flaticon-arrow-pointing-to-right"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!--Gallery Item-->
                    <div class="gallery-item mix all commidities management cumultation commidities col-lg-4 col-md-6 col-sm-12">
                        <div class="inner-box">
                            <div class="image">
                            	<img src="assets/images/gallery/19.jpg" alt="" />
                                <div class="overlay-box">
                                	<div class="content">
                                    	<ul>
                                        	<li><a href="project-detail.html">Business</a></li>
                                            <li><a href="project-detail.html">Corporate</a></li>
                                        </ul>
                                        <h3><a href="project-detail.html">Assets For Technology</a></h3>
                                    </div>
                                    <a class="read-more" href="project-detail.html">Read More <span class="icon flaticon-arrow-pointing-to-right"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!--Gallery Item-->
                    <div class="gallery-item mix all commidities business col-lg-4 col-md-6 col-sm-12">
                        <div class="inner-box">
                            <div class="image">
                            	<img src="assets/images/gallery/20.jpg" alt="" />
                                <div class="overlay-box">
                                	<div class="content">
                                    	<ul>
                                        	<li><a href="project-detail.html">Business</a></li>
                                            <li><a href="project-detail.html">Corporate</a></li>
                                        </ul>
                                        <h3><a href="project-detail.html">Assets For Technology</a></h3>
                                    </div>
                                    <a class="read-more" href="project-detail.html">Read More <span class="icon flaticon-arrow-pointing-to-right"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!--Gallery Item-->
                    <div class="gallery-item mix all business cumultation col-lg-4 col-md-6 col-sm-12">
                        <div class="inner-box">
                            <div class="image">
                            	<img src="assets/images/gallery/21.jpg" alt="" />
                                <div class="overlay-box">
                                	<div class="content">
                                    	<ul>
                                        	<li><a href="project-detail.html">Business</a></li>
                                            <li><a href="project-detail.html">Corporate</a></li>
                                        </ul>
                                        <h3><a href="project-detail.html">Assets For Technology</a></h3>
                                    </div>
                                    <a class="read-more" href="project-detail.html">Read More <span class="icon flaticon-arrow-pointing-to-right"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!--Gallery Item-->
                    <div class="gallery-item mix all commidities col-lg-4 col-md-6 col-sm-12">
                        <div class="inner-box">
                            <div class="image">
                            	<img src="assets/images/gallery/22.jpg" alt="" />
                                <div class="overlay-box">
                                	<div class="content">
                                    	<ul>
                                        	<li><a href="project-detail.html">Business</a></li>
                                            <li><a href="project-detail.html">Corporate</a></li>
                                        </ul>
                                        <h3><a href="project-detail.html">Assets For Technology</a></h3>
                                    </div>
                                    <a class="read-more" href="project-detail.html">Read More <span class="icon flaticon-arrow-pointing-to-right"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!--Gallery Item-->
                    <div class="gallery-item mix all commidities management cumultation col-lg-4 col-md-6 col-sm-12">
                        <div class="inner-box">
                            <div class="image">
                            	<img src="assets/images/gallery/23.jpg" alt="" />
                                <div class="overlay-box">
                                	<div class="content">
                                    	<ul>
                                        	<li><a href="project-detail.html">Business</a></li>
                                            <li><a href="project-detail.html">Corporate</a></li>
                                        </ul>
                                        <h3><a href="project-detail.html">Assets For Technology</a></h3>
                                    </div>
                                    <a class="read-more" href="project-detail.html">Read More <span class="icon flaticon-arrow-pointing-to-right"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
				
                </div>
                
            </div>
            
            <!--Styled Pagination-->
            <ul class="styled-pagination text-center">
                <li class="prev"><a href="#"><span class="fa fa-long-arrow-left"></span></a></li>
                <li><a href="#">1</a></li>
                <li><a href="#" class="active">2</a></li>
                <li><a href="#">3</a></li>
                <li class="next"><a href="#"><span class="fa fa-long-arrow-right"></span></a></li>
            </ul>                
            <!--End Styled Pagination-->
            
        </div>
    </section>
    <!--End Project Page Section-->
<app-contact-banner></app-contact-banner>