<!-- Main Header-->
<header class="main-header">

    <!-- Header Top -->
    <div class="header-top">
        <div class="auto-container">
            <div class="inner-container clearfix">

                <!--Top Left-->
                <div class="top-left">
                    <ul class="links clearfix">
                        <li><a href="#"><span class="icon fa fa-phone"></span> +27 13 110 4907</a></li>
                        <li><a href="#"><span class="icon fa fa-whatsapp"></span> +27 64 084 6775</a></li>
                        <li><a href="#"><span class="icon fa fa-envelope"></span> info&#64;inertiaacc.co.za</a></li>
                        <li><a href="#"><span class="icon fa fa-map-marker"></span> 135, 36 Mandela St, Witbank
                                1039, South Africa</a></li>
                    </ul>
                </div>

                <!--Top Right-->
                <div class="top-right clearfix">
                    <!--social-icon-->
                    <div class="social-icon">
                        <ul class="clearfix">
                            <li><a href="#"><span class="fa fa-facebook"></span></a></li>
                            <li><a href="#"><span class="fa fa-twitter"></span></a></li>
                            <li><a href="#"><span class="fa fa-google-plus"></span></a></li>
                            <li><a href="#"><span class="fa fa-linkedin"></span></a></li>
                        </ul>
                    </div>
                </div>

            </div>

        </div>
    </div>
    <!-- Header Top End -->

    <!-- Main Box -->
    <div class="main-box">
        <div class="auto-container">
            <div class="outer-container clearfix">
                <!--Logo Box-->
                <div class="logo-box">
                    <div class="logo"><a [routerLink]="['/']"><img src="assets/images/logo.svg" alt=""
                                style="max-height: 50px; margin-top: 15px;"></a></div>
                </div>

                <!--Nav Outer-->
                <div class="nav-outer clearfix">

                    <!-- Main Menu -->
                    <nav class="main-menu navbar-expand-md">
                        <div class="navbar-header">
                            <!-- Toggle Button -->
                            <button class="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                            </button>
                        </div>

                        <div class="navbar-collapse collapse clearfix" id="navbarSupportedContent">
                            <ul class="navigation clearfix">
                                <li [routerLinkActive]="['current']"><a [routerLink]="['/']">Home</a>
                                </li>
                                <li [routerLinkActive]="['current']"><a [routerLink]="['/about-us']">About Us</a>
                                </li>
                                <li [routerLinkActive]="['current']"><a [routerLink]="['/our-services']">Our Services</a>
                                </li>
                                <!-- <li [routerLinkActive]="['current']"><a [routerLink]="['/our-projects']">Our Process</a>
                                </li> -->
                                <li [routerLinkActive]="['current']"><a [routerLink]="['/contact-us']">Contact us</a></li>
                            </ul>
                        </div>
                    </nav>
                    <!-- Main Menu End-->
                    <div class="options-box">

                        <!--Cart Box-->
                        <!-- <div class="cart-box">
                          <a href="#"><span class="fa fa-shopping-cart"></span></a>
                          </div> -->

                        <!--Search Box-->
                        <div class="search-box-outer">
                            <div class="dropdown">
                                <button class="search-box-btn dropdown-toggle" type="button" id="dropdownMenu3"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span
                                        class="fa fa-search"></span></button>
                                <ul class="dropdown-menu pull-right search-panel" aria-labelledby="dropdownMenu3">
                                    <li class="panel-outer">
                                        <div class="form-container">
                                            <form method="post" action="https://expert-themes.com/html/expo/blog.html">
                                                <div class="form-group">
                                                    <input type="search" name="field-name" value=""
                                                        placeholder="Search Here" required>
                                                    <button type="submit" class="search-btn"><span
                                                            class="fa fa-search"></span></button>
                                                </div>
                                            </form>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <!--Quote Btn-->
                        <div class="quote-btn">
                            <a href="/contact-us?subject=Request For Quote" class="theme-btn btn-style-one">Get a quote</a>
                        </div>

                    </div>
                </div>
                <!--Nav Outer End-->

            </div>
        </div>
    </div>

    <!--Sticky Header-->
    <div class="sticky-header">
        <div class="auto-container">
            <div class="sticky-inner-container clearfix">
                <!--Logo-->
                <div class="logo pull-left">
                    <a [routerLink]="['/home']" class="img-responsive"><img src="assets/images/logo.svg" alt=""
                            style="max-height: 50px; margin-top: 5px;" title=""></a>
                </div>

                <!--Right Col-->
                <div class="right-col pull-right">
                    <!-- Main Menu -->
                    <nav class="main-menu navbar-expand-md">
                        <button class="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </button>

                        <div class="navbar-collapse collapse clearfix" id="navbarSupportedContent1">
                            <ul class="navigation clearfix">
                                <li [routerLinkActive]="['current']"><a [routerLink]="['/']">Home</a>
                                </li>
                                <li [routerLinkActive]="['current']"><a [routerLink]="['/about-us']">About Us</a>
                                </li>
                                <li [routerLinkActive]="['current']"><a [routerLink]="['/our-services']">Our Services</a>
                                </li>
                                <!-- <li [routerLinkActive]="['current']"><a [routerLink]="['/our-projects']">Our Proccess</a>
                                </li> -->
                                <li [routerLinkActive]="['current']"><a [routerLink]="['/contact-us']">Contact us</a></li>
                            </ul>
                        </div>
                    </nav>
                    <!-- Main Menu End-->

                </div>

            </div>
        </div>
    </div>
    <!--End Sticky Header-->

</header>
<!--End Main Header -->