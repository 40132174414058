<section class="section team-area team-area-1 ptb-60 bg-gray">
    <div class="container">
        <div class="row mb-30">
            <div class="col-lg-7 col-md-8 col-sm-10 col-xs-12 col-xs-center t-center mb-40">
                <h6 class="section-subtitle mb-10 t-uppercase color-mid">Meet our experienced team</h6>
                <h2 class="section-title mb-20 font-22 t-uppercase">Our Experts</h2>
                <div class="line"></div>
            </div>
        </div>
        <div class="team-members row">
            <div class="col-md-3 col-sm-6">
                <div class="single-member">
                    <div class="single-member-header pt-15">
                        <img src="assets/images/team/t1.png" alt="">
                        <div class="single-member-overlay t-center">
                            <ul class="social-icons list-inline is-inline-block pos-tb-center">
                                <li class="social-icons__item">
                                    <a href="#"><i class="fa fa-facebook"></i></a>
                                </li>
                                <li class="social-icons__item">
                                    <a href="#"><i class="fa fa-twitter"></i></a>
                                </li>
                                <li class="social-icons__item">
                                    <a href="#"><i class="fa fa-linkedin"></i></a>
                                </li>
                                <li class="social-icons__item">
                                    <a href="#"><i class="fa fa-google-plus"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="single-member-content">
                        <h5 class="mb-5">ROBART JASON</h5>
                        <p class="color-theme mb-10">ARCHITECT</p>
                        <p class="color-mid">Howdy, I am Martin from England, I am founder, CEO and working at Iceland company.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="single-member">
                    <div class="single-member-header pt-15">
                        <img src="assets/images/team/t2.png" alt="">
                        <div class="single-member-overlay t-center">
                            <ul class="social-icons list-inline is-inline-block pos-tb-center">
                                <li class="social-icons__item">
                                    <a href="#"><i class="fa fa-facebook"></i></a>
                                </li>
                                <li class="social-icons__item">
                                    <a href="#"><i class="fa fa-twitter"></i></a>
                                </li>
                                <li class="social-icons__item">
                                    <a href="#"><i class="fa fa-linkedin"></i></a>
                                </li>
                                <li class="social-icons__item">
                                    <a href="#"><i class="fa fa-google-plus"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="single-member-content">
                        <h5 class="mb-5">MARTIN DOE</h5>
                        <p class="color-theme mb-10">SUPERVISOR</p>
                        <p class="color-mid">Howdy, I am Martin from England, I am founder, CEO and working at Iceland company.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="single-member">
                    <div class="single-member-header pt-15">
                        <img src="assets/images/team/t3.png" alt="">
                        <div class="single-member-overlay t-center">
                            <ul class="social-icons list-inline is-inline-block pos-tb-center">
                                <li class="social-icons__item">
                                    <a href="#"><i class="fa fa-facebook"></i></a>
                                </li>
                                <li class="social-icons__item">
                                    <a href="#"><i class="fa fa-twitter"></i></a>
                                </li>
                                <li class="social-icons__item">
                                    <a href="#"><i class="fa fa-linkedin"></i></a>
                                </li>
                                <li class="social-icons__item">
                                    <a href="#"><i class="fa fa-google-plus"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="single-member-content">
                        <h5 class="mb-5">JOHN DOE</h5>
                        <p class="color-theme mb-10">MAIN ARCHITECT</p>
                        <p class="color-mid">Howdy, I am Martin from England, I am founder, CEO and working at Iceland company.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="single-member">
                    <div class="single-member-header pt-15">
                        <img src="assets/images/team/t4.png" alt="">
                        <div class="single-member-overlay t-center">
                            <ul class="social-icons list-inline is-inline-block pos-tb-center">
                                <li class="social-icons__item">
                                    <a href="#"><i class="fa fa-facebook"></i></a>
                                </li>
                                <li class="social-icons__item">
                                    <a href="#"><i class="fa fa-twitter"></i></a>
                                </li>
                                <li class="social-icons__item">
                                    <a href="#"><i class="fa fa-linkedin"></i></a>
                                </li>
                                <li class="social-icons__item">
                                    <a href="#"><i class="fa fa-google-plus"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="single-member-content">
                        <h5 class="mb-5">MARTIN CROW</h5>
                        <p class="color-theme mb-10">ARCHITECT</p>
                        <p class="color-mid">Howdy, I am Martin from England, I am founder, CEO and working at Iceland company.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
