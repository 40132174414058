

<!-- Main Footer -->
<footer class="main-footer" style="background-image: url(assets/images/background/4.jpg);">
  <div class="auto-container">

    <!--Widgets Section-->
    <div class="widgets-section">
      <div class="row clearfix">

        <!--Big Column-->
        <div class="big-column col-lg-6 col-md-12 col-sm-12">
          <div class="row clearfix">

            <!--Footer Column-->
            <div class="big-column col-lg-7 col-md-7 col-sm-12">
              <div class="footer-widget logo-widget">
                <div class="logo">
                  <a [routerLink]="['/home']"><img src="assets/images/footer-logo.svg" alt="" /></a>
                </div>
               <p>Our team of experienced accountants, registered tax practitioners and advisors, provide expert advice and support to private clients and their businesses helping them to achieve their financial goals.  </p>
              </div>
            </div>
            <!--Footer Column-->
            <div class="big-column col-lg-5 col-md-5 col-sm-12">
              <div class="footer-widget news-widget">
                <h2>Quick Links</h2>
                <ul class="list-style-one">
                  <li><a [routerLink]="['/home']"><span class="icon fa fa-angle-double-right"></span>Home</a></li>
                  <li><a [routerLink]="['/about-us']"><span class="icon fa fa-angle-double-right"></span>About Us</a></li>
                  <li><a [routerLink]="['/our-services']"><span class="icon fa fa-angle-double-right"></span>Our Services</a></li>
                  <li><a [routerLink]="['/our-projects']"><span class="icon fa fa-angle-double-right"></span>Our Projects</a></li>
                  <li><a [routerLink]="['/contact-us']"><span class="icon fa fa-angle-double-right"></span>Contact Us</a></li>
                </ul>

              </div>
            </div>

          </div>
        </div>

        <!--Big Column-->
        <div class="big-column col-lg-6 col-md-12 col-sm-12">
          <div class="row clearfix">
            <!--Footer Column-->
            <div class="big-column col-lg-6 col-md-6 col-sm-12">
              <div class="footer-widget hours-widget">
                <h2>Working Hours</h2>
                <div class="widget-content">
                  <div class="text">We work Mondays to Saturdays. Please note: <b>Office visits are strictly by Appointment.</b></div>
                  <ul>
                    <li class="clearfix">Monday to Friday<span>08:00 to 17:00</span></li>
                    <li class="clearfix">Saturday<span>09:00 to 14:00</span></li>
                    <li class="clearfix">Sunday<span>CLOSED</span></li>
                  </ul>
                </div>
              </div>
            </div>
            <!--Footer Column-->
            <div class="footer-column col-lg-6 col-md-6 col-sm-12">
              <div class="footer-widget branches-widget">
                <h2>Contact Details</h2>
                <ul class="list-style-one">
                  <li><span class="icon fa fa-map-marker"></span>135, 36 Mandela St,<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Witbank 1039, South Africa</li>
                  <li><span class="icon fa fa-envelope"></span>info&#64;inertiaacc.co.za</li>
                  <li><span class="icon fa fa-envelope"></span>valeria&#64;inertiaacc.co.za</li>
                  <li><span class="icon fa fa-phone"></span>+27 13 110 4907</li>
                  <li><span class="icon fa fa-whatsapp"></span>+27 64 084 6775</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
  <div class="footer-bottom">
    <div class="auto-container">
      <div class="row clearfix">

        <div class="column col-lg-7 col-md-12 col-sm-12">
          <div class="copyright">Copyrights 2021. All Rights reserved.  Developed by <a class="theme_color"
              href="wattext.com">Wattext Technologies Pty Ltd</a></div>
        </div>

        <div class="social-column col-lg-5 col-md-12 col-sm-12">
          <ul class="social-icon-one">
            <li><a href="#"><span class="fa fa-facebook"></span></a></li>
            <li><a href="#"><span class="fa fa-twitter"></span></a></li>
            <li><a href="#"><span class="fa fa-google-plus"></span></a></li>
            <li><a href="#"><span class="fa fa-linkedin"></span></a></li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</footer>