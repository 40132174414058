<section class="section services-area ptb-60">
    <div class="container">
        <div class="row mb-30">
            <div class="col-lg-7 col-md-8 col-sm-10 col-xs-12 col-xs-center t-center mb-40">
                <h6 class="section-subtitle mb-10 t-uppercase color-mid">OUR OUTSTANDING SERVICES</h6>
                <h2 class="section-title mb-20 font-22 t-uppercase">WHAT WE OFFER</h2>
                <div class="line"></div>
            </div>
        </div>
        <div class="owl-carousel services-slider"
            data-loop="true"
            data-autoplay="true"
            data-autoplay-timeout="5000"
            data-smart-speed="1000"
            data-nav-speed="false"
            data-dots="true"
            data-nav="false"
            data-lg-nav="false"
            data-md-nav="false"
            data-sm-nav="false"
            data-xs-nav="false"
            data-xxs-nav="false"
            data-lg-items="3"
            data-md-items="3"
            data-sm-items="2"
            data-xs-items="1"
            data-xxs-items="1">								
            <div class="item">
                <div class="service-single">
                    <div class="service-thumb">
                        <img src="assets/images/services/01.jpg" alt="">
                    </div>
                    <div class="service-content">
                        <h5 class="mb-10 t-uppercase">PRECONSTRUCTION</h5>
                        <p class="color-mid mb-15">Vestibulum nunc ante, sagittis ut nisl at, porta porttitor justo. Nam imperdiet ...</p>
                        <a class="read-more" href="#">Read more</a>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="service-single">
                    <div class="service-thumb">
                        <img src="assets/images/services/02.jpg" alt="">
                    </div>
                    <div class="service-content">
                        <h5 class="mb-10 t-uppercase">TILING & PAINTING</h5>
                        <p class="color-mid mb-15">Vestibulum nunc ante, sagittis ut nisl at, porta porttitor justo. Nam imperdiet ...</p>
                        <a class="read-more" href="#">Read more</a>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="service-single">
                    <div class="service-thumb">
                        <img src="assets/images/services/03.jpg" alt="">
                    </div>
                    <div class="service-content">
                        <h5 class="mb-10 t-uppercase">ARCHITECTURAL DESIGN</h5>
                        <p class="color-mid mb-15">Vestibulum nunc ante, sagittis ut nisl at, porta porttitor justo. Nam imperdiet ...</p>
                        <a class="read-more" href="#">Read more</a>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="service-single">
                    <div class="service-thumb">
                        <img src="assets/images/services/04.jpg" alt="">
                    </div>
                    <div class="service-content">
                        <h5 class="mb-10 t-uppercase">PRECONSTRUCTION</h5>
                        <p class="color-mid mb-15">Vestibulum nunc ante, sagittis ut nisl at, porta porttitor justo. Nam imperdiet ...</p>
                        <a class="read-more" href="#">Read more</a>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="service-single">
                    <div class="service-thumb">
                        <img src="assets/images/services/05.jpg" alt="">
                    </div>
                    <div class="service-content">
                        <h5 class="mb-10 t-uppercase">TILING & PAINTING</h5>
                        <p class="color-mid mb-15">Vestibulum nunc ante, sagittis ut nisl at, porta porttitor justo. Nam imperdiet ...</p>
                        <a class="read-more" href="#">Read more</a>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="service-single">
                    <div class="service-thumb">
                        <img src="assets/images/services/06.jpg" alt="">
                    </div>
                    <div class="service-content">
                        <h5 class="mb-10 t-uppercase">ARCHITECTURAL DESIGN</h5>
                        <p class="color-mid mb-15">Vestibulum nunc ante, sagittis ut nisl at, porta porttitor justo. Nam imperdiet ...</p>
                        <a class="read-more" href="#">Read more</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
