import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feature-area',
  templateUrl: './feature-area.component.html',
  styleUrls: ['./feature-area.component.css']
})
export class FeatureAreaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
