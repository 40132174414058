import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
@Injectable()
export class UsersService {
    constructor(public httpClient: HttpClient) {
    }
    public contactUs(contactRequestModel: any): Observable<any> {
       
        return this.httpClient.post("https://inertiaacc.co.za/email.php", JSON.stringify(contactRequestModel));
    }
}