<section class="section about-us-area ptb-60">
    <div class="container">
        <div class="row row-tb-15 mb-40 clearfix">
            <div class="col-md-6">
                <div class="who-we-are">
                    <h5 class="mb-10">About our company</h5>
                    <h3 class="mb-20">Welcome to our Construction  Services</h3>
                    <p class="color-mid mb-20">Duis tincidunt leo sed dolor lacinia posuere. Nulla eu aliquam enim. Proin et nibh ut massa lacinia lacinia. Duis sit amet dictum nisl, vitae porttitor nunc.</p>
                    <ul class="styled-list mb-30">
                        <li>Nulla venenatis auctor quam, ac porta lectus dictum in</li>
                        <li>Aliquam sodales nisi sit amet lorem</li>
                        <li>Suspendisse vulputate tellus in justo convallis imperdiet</li>
                        <li>Morbi id lorem consequat, sodales mi non</li>
                        <li>Nulla venenatis auctor quam, ac porta lectus dictum in</li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6">
                <div class="owl-carousel"
                    data-loop="true"
                    data-autoplay="true"
                    data-autoplay-timeout="5000"
                    data-smart-speed="1000"
                    data-nav-speed="false"
                    data-nav="true">
                    <img class="img-responsive" src="assets/images/about/07.jpg" alt="">
                    <img class="img-responsive" src="assets/images/about/08.jpg" alt="">
                    <img class="img-responsive" src="assets/images/about/09.jpg" alt="">
                    <img class="img-responsive" src="assets/images/about/10.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="row row-tb-15 clearfix">
            <div class="col-md-3 col-sm-6">
                <div class="about-us-box">
                    <figure class="embed-responsive embed-responsive-4by3 mb-15" data-bg-img="assets/images/services/07.jpg">
                        <a href="#" class="link-overlay t-center"><i class="fa fa-link pos-center"></i></a>
                    </figure>
                    <h6 class="mb-10 t-uppercase">Renovation</h6>
                    <p class="color-mid mb-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus pretium orci.</p>
                    <a href="#" class="read-more">Read More </a>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="about-us-box">
                    <figure class="embed-responsive embed-responsive-4by3 mb-15" data-bg-img="assets/images/services/08.jpg">
                        <a href="#" class="link-overlay t-center"><i class="fa fa-link pos-center"></i></a>
                    </figure>
                    <h6 class="t-uppercase mb-10">BUILDING ENGINEERING</h6>
                    <p class="color-mid mb-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus pretium orci.</p>
                    <a href="#" class="read-more">Read More </a>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="about-us-box">
                    <figure class="embed-responsive embed-responsive-4by3 mb-15" data-bg-img="assets/images/services/09.jpg">
                        <a href="#" class="link-overlay t-center"><i class="fa fa-link pos-center"></i></a>
                    </figure>
                    <h6 class="t-uppercase mb-10">ARCHITECTURE</h6>
                    <p class="color-mid mb-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus pretium orci.</p>
                    <a href="#" class="read-more">Read More </a>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="about-us-box">
                    <figure class="embed-responsive embed-responsive-4by3 mb-15" data-bg-img="assets/images/services/10.jpg">
                        <a href="#" class="link-overlay t-center"><i class="fa fa-link pos-center"></i></a>
                    </figure>
                    <h6 class="mb-10 t-uppercase">CONSTRUCTION SERVICES</h6>
                    <p class="color-mid mb-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus pretium orci.</p>
                    <a href="#" class="read-more">Read More </a>
                </div>
            </div>
        </div>
    </div>
</section>