import { Component, OnInit } from '@angular/core';
import { NewsArticle } from '../../models/news-article';
import *  as  data from "../../../assets/news.json";
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public news: NewsArticle[] =  (data as any).default;
  constructor() { }

  ngOnInit(): void {
  }

}
