import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accounting-services',
  templateUrl: './accounting-services.component.html',
  styleUrls: ['./accounting-services.component.css']
})
export class AccountingServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
