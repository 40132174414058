<!--Main Slider-->
<section class="main-slider">
  
    <div class="rev_slider_wrapper fullwidthbanner-container"  id="rev_slider_one_wrapper" data-source="gallery">
        <div class="rev_slider fullwidthabanner" id="rev_slider_one" data-version="5.4.1">
            <ul>
              
                <li data-description="Slide Description" data-easein="default" data-easeout="default" data-fsmasterspeed="1500" data-fsslotamount="7" data-fstransition="fade" data-hideafterloop="0" data-hideslideonmobile="off" data-index="rs-1687" data-masterspeed="default" data-param1="" data-param10="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-rotate="0" data-saveperformance="off" data-slotamount="default" data-thumb="images/main-slider/image-1.jpg" data-title="Slide Title" data-transition="parallaxvertical">
                <img alt="" class="rev-slidebg" data-bgfit="cover" data-bgparallax="10" data-bgposition="center center" data-bgrepeat="no-repeat" data-no-retina="" src="assets/images/main-slider/image-1.jpg"> 
                
                <div class="tp-caption tp-resizeme"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingtop="[0,0,0,0]"
                data-responsive_offset="on"
                data-type="text"
                data-height="none"
                data-width="['600','600','650','490']"
                data-whitespace="normal"
                data-hoffset="['15','15','15','15']"
                data-voffset="['60','0','0','0']"
                data-x="['left','left','left','left']"
                data-y="['middle','middle','middle','middle']"
                data-textalign="['top','top','top','top']"
                data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'>
                  <div class="slider-content">
                      <div class="title">we are accouting professionals offering :</div>
                      <h2>Accounting</h2>
                        <div class="text">We ensure compliance with International Financial Reporting Standards (IFRS) </div>
                        <a href="/about-us/" class="theme-btn btn-style-two">Learn More</a><a href="/our-services/" class="theme-btn btn-style-three">Our Service</a>
                    </div>
                </div>
                
                </li>
                
                <li data-description="Slide Description" data-easein="default" data-easeout="default" data-fsmasterspeed="1500" data-fsslotamount="7" data-fstransition="fade" data-hideafterloop="0" data-hideslideonmobile="off" data-index="rs-1688" data-masterspeed="default" data-param1="" data-param10="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-rotate="0" data-saveperformance="off" data-slotamount="default" data-thumb="images/main-slider/image-2.jpg" data-title="Slide Title" data-transition="parallaxvertical">
                <img alt="" class="rev-slidebg" data-bgfit="cover" data-bgparallax="10" data-bgposition="center center" data-bgrepeat="no-repeat" data-no-retina="" src="assets/images/main-slider/image-2.jpg"> 
                
                <div class="tp-caption tp-resizeme"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingtop="[0,0,0,0]"
                data-responsive_offset="on"
                data-type="text"
                data-height="none"
                data-width="['600','600','650','490']"
                data-whitespace="normal"
                data-hoffset="['15','15','15','15']"
                data-voffset="['60','0','0','0']"
                data-x="['left','left','left','left']"
                data-y="['middle','middle','middle','middle']"
                data-textalign="['top','top','top','top']"
                data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'>
                  <div class="slider-content">
                      <div class="title">we are accouting professionals offering :</div>
                      <h2>Taxation & Payroll</h2>
                        <div class="text">Our Taxation department is fully trained and keep abreast with all changes in the legislation & Tax Returns</div>
                        <a href="/about-us/" class="theme-btn btn-style-two">Learn More</a><a href="/our-services/" class="theme-btn btn-style-three">Our Service</a>
                    </div>
                </div>
                
                </li>
                
                <li data-description="Slide Description" data-easein="default" data-easeout="default" data-fsmasterspeed="1500" data-fsslotamount="7" data-fstransition="fade" data-hideafterloop="0" data-hideslideonmobile="off" data-index="rs-1691" data-masterspeed="default" data-param1="" data-param10="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-rotate="0" data-saveperformance="off" data-slotamount="default" data-thumb="images/main-slider/image-3.jpg" data-title="Slide Title" data-transition="parallaxvertical">
                <img alt="" class="rev-slidebg" data-bgfit="cover" data-bgparallax="10" data-bgposition="center center" data-bgrepeat="no-repeat" data-no-retina="" src="assets/images/main-slider/image-3.jpeg"> 
                
                <div class="tp-caption tp-resizeme"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingtop="[0,0,0,0]"
                data-responsive_offset="on"
                data-type="text"
                data-height="none"
                data-width="['600','600','650','490']"
                data-whitespace="normal"
                data-hoffset="['15','15','15','15']"
                data-voffset="['60','0','0','0']"
                data-x="['left','left','left','left']"
                data-y="['middle','middle','middle','middle']"
                data-textalign="['top','top','top','top']"
                data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":1000,"ease":"Power3.easeInOut"},{"delay":"wait","speed":1000,"to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'>
                  <div class="slider-content">
                      <div class="title">we are accouting professionals offering :</div>
                      <h2>Compensation Fund</h2>
                        <div class="text">Our team of experienced accountants, registered tax practitioners and advisors can provide you expert advice </div>
                        <a href="/about-us/" class="theme-btn btn-style-two">Learn More</a><a href="/our-services/" class="theme-btn btn-style-three">Our Service</a>
                    </div>
                </div>
                
                </li>
                
            </ul>
            
        </div>
    </div>
</section>
<!--End Main Slider-->

<!--Welcome Section-->
<section class="welcome-section">
  <div class="auto-container">
      <div class="row clearfix">
          
            <!--Content Column-->
            <div class="content-column col-lg-7 col-md-12 col-sm-12">
              <div class="inner-column">
                  <!--Sec Title-->
                    <div class="sec-title">
                      <div class="title">Welcome to Our site</div>
                        <h2>INERTIA ACCOUNTING SERVICES</h2>
                    </div>
                    <div class="text">We work to bring the figures to life and help our clients understand exactly what they mean and what they implicate for their business. To ensure we tailor our approach to your business we always start by gaining a thorough understanding of you and your business.</div>
                    <div class="clearfix">
                      <div class="pull-left">
                          <a [routerLink]="['/about-us']" class="theme-btn btn-style-four">Read More</a>
                        </div>
                        <div class="pull-right">
                          <div class="phone"><span class="icon fa fa-phone"></span> +27 13 110 4907</div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!--Image Column-->
            <div class="image-column col-lg-5 col-md-12 col-sm-12">
              <div class="inner-column">
                  <div class="image">
                      <img src="assets/images/logo.png" alt="" />
                        <a href="#" target="_blank" class="lightbox-image play-btn hidden" style="display: none;">
                            <span class="icon fa fa-play"></span>
                        </a>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</section>
<!--End Welcome Section-->

<!--Approach Section-->
<section class="approach-section" style="background-image:url(assets/images/background/2.jpg)">
  <div class="upper-box" style="background-image:url(assets/images/background/1.jpg)">
      <div class="auto-container">
            <div class="title">WE ARE ACCOUNTING PROFESSIONALS</div>
            <div class="quality">Our Main Services</div>
        </div>
    </div>
    
    <div class="auto-container">
      <div class="blocks-section">
          <div class="row clearfix">
              
                <!--Services Block-->
                <div class="services-block col-lg-4 col-md-6 col-sm-12">
                  <div class="inner-box">
                      <div class="icon-box">
                          <span class="icon flaticon-employee"></span>
                        </div>
                        <h3><a href="/our-services/accounting">Accounting</a></h3>
                        <div class="text">Processing of accounting records & Preparation of monthly and/or quarterly accounts
                            </div>
                        <div class="overlay-box" style="background-image:url(assets/images/resource/service-1.jpg)">
                          <div class="overlay-inner">
                              <div class="icon-box">
                                    <span class="icon flaticon-employee"></span>
                                </div>
                                <h4><a href="/our-services/accounting">Accounting</a></h4>
                                <div class="overlay-text">Processing of accounting records & Preparation of monthly and/or quarterly accounts</div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!--Services Block-->
                <div class="services-block col-lg-4 col-md-6 col-sm-12">
                  <div class="inner-box">
                      <div class="icon-box">
                          <span class="icon flaticon-money"></span>
                        </div>
                        <h3><a href="/our-services/bookkeeping">Bookkeeping</a></h3>
                        <div class="text">Reconcile bank statements to internal accounts and help out during an internal or IRS audit.</div>
                        <div class="overlay-box" style="background-image:url(assets/images/resource/service-1.jpg)">
                          <div class="overlay-inner">
                              <div class="icon-box">
                                    <span class="icon flaticon-money"></span>
                                </div>
                                <h4><a href="/our-services/bookkeeping">Bookkeeping</a></h4>
                                <div class="overlay-text">Reconcile bank statements to internal accounts and help out during an internal or IRS audit.</div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!--Services Block-->
                <div class="services-block col-lg-4 col-md-6 col-sm-12">
                  <div class="inner-box">
                      <div class="icon-box">
                          <span class="icon flaticon-line-chart"></span>
                        </div>
                        <h3><a href="/our-services/taxation">Taxation</a></h3>
                        <div class="text">Personal and Business Tax Planning, Submission of returns with SARS
                            </div>
                        <div class="overlay-box" style="background-image:url(assets/images/resource/service-1.jpg)">
                          <div class="overlay-inner">
                              <div class="icon-box">
                                    <span class="icon flaticon-employee"></span>
                                </div>
                                <h4><a href="/our-services/taxation">Taxation</a></h4>
                                <div class="overlay-text">Personal and Business Tax Planning, Submission of returns with SARS</div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!--Services Block-->
                <div class="services-block col-lg-4 col-md-6 col-sm-12">
                  <div class="inner-box">
                      <div class="icon-box">
                          <span class="icon flaticon-pen"></span>
                        </div>
                        <h3><a href="/our-services/payroll">Payroll</a></h3>
                        <div class="text">We offer a comprehensive weekly, bi-monthly and monthly payroll services</div>
                        <div class="overlay-box" style="background-image:url(assets/images/resource/service-1.jpg)">
                          <div class="overlay-inner">
                              <div class="icon-box">
                                    <span class="icon flaticon-pen"></span>
                                </div>
                                <h4><a href="/our-services/payroll">Payroll</a></h4>
                                <div class="overlay-text">We offer a comprehensive weekly, bi-monthly and monthly payroll services</div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!--Services Block-->
                <div class="services-block col-lg-4 col-md-6 col-sm-12">
                  <div class="inner-box">
                      <div class="icon-box">
                          <span class="icon flaticon-meeting"></span>
                        </div>
                        <h3><a href="/our-services/compensation-fund">Compensation Fund</a></h3>
                        <div class="text">You can transform your business to a asset coaching programs</div>
                        <div class="overlay-box" style="background-image:url(assets/images/resource/service-1.jpg)">
                          <div class="overlay-inner">
                              <div class="icon-box">
                                    <span class="icon flaticon-meeting"></span>
                                </div>
                                <h4><a href="/our-services/compensation-fund">Compensation Fund</a></h4>
                                <div class="overlay-text">You can transform your business to a asset coaching programs</div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!--Services Block-->
                <div class="services-block col-lg-4 col-md-6 col-sm-12">
                  <div class="inner-box">
                      <div class="icon-box">
                          <span class="icon flaticon-bank-1"></span>
                        </div>
                        <h3><a href="/our-services/secretarial">Secretarial Services</a></h3>
                        <div class="text">Registrations of new legal entities (Pty & NPC), Annual returns at CIPC & BBBEE Certificates
                            </div>
                        <div class="overlay-box" style="background-image:url(assets/images/resource/service-1.jpg)">
                          <div class="overlay-inner">
                              <div class="icon-box">
                                    <span class="icon flaticon-employee"></span>
                                </div>
                                <h4><a href="/our-services/secretarial">Secretarial Services</a></h4>
                                <div class="overlay-text">Registrations of new legal entities (Pty & NPC), Annual returns at CIPC & BBBEE Certificates</div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    
    <!--Steps Section-->
    <div class="steps-section">
      <div class="auto-container">
          <div class="sec-title">
              <div class="title">How We Work</div>
                <h2>4 Steps To Make Successful Business</h2>
            </div>
            <div class="row clearfix">
              
                <!--Step Block-->
                <div class="step-block col-lg-3 col-md-6 col-sm-12">
                  <div class="inner-box">
                      <div class="number"><span>1 -</span>  Analize</div>
                        <div class="text">We utilizes creative and customized methods that tailor our work to the client results.</div>
                    </div>
                </div>
                
                <!--Step Block-->
                <div class="step-block col-lg-3 col-md-6 col-sm-12">
                  <div class="inner-box">
                      <div class="number"><span>2 -</span>  Advise</div>
                        <div class="text">Find out when where business needs to go and how to get there – real progress is made.</div>
                    </div>
                </div>
                
                <!--Step Block-->
                <div class="step-block col-lg-3 col-md-6 col-sm-12">
                  <div class="inner-box">
                      <div class="number"><span>3 -</span>  Strategy</div>
                        <div class="text">We deliver business results via hands-on execution and leading teams through complex change.</div>
                    </div>
                </div>
                
                <!--Step Block-->
                <div class="step-block col-lg-3 col-md-6 col-sm-12">
                  <div class="inner-box">
                      <div class="number"><span>4 -</span>  Result</div>
                        <div class="text">We provide valuable guidance and support in the development, and you run a successful business.</div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    
</section>
<!--End Approach Section-->


<!--Consulting Section-->
<section class="consulting-section" style="background-image:url(assets/images/background/3.jpg)">
  <div class="auto-container">
      <div class="row clearfix">
          <!--Form Column-->
            <div class="form-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                  <!-- Consulting Form -->
                    <div class="consulting-form">
                        <form method="post" action="">
                            <div class="row clearfix">
                                <div class="form-group col-lg-12 col-md-12 col-sm-12">
                                    <select class="custom-select-box">
                                        <option>Accounting Management</option>
                                        <option>Management One</option>
                                        <option>Management Two</option>
                                        <option>Management Three</option>
                                        <option>Management Four</option>
                                    </select>
                                </div>
                                
                                <div class="form-group col-lg-12 col-md-12 col-sm-12">
                                    <input type="text" name="username" placeholder="Name" required>
                                </div>
            
                                <div class="form-group col-lg-12 col-md-12 col-sm-12">
                                    <input type="email" name="email" placeholder="Email" required>
                                </div>
                                
                                <div class="form-group col-lg-12 col-md-12 col-sm-12">
                                    <input type="text" name="phone" placeholder="Phone" required>
                                </div>
                                
                                <div class="form-group col-lg-12 col-md-12 col-sm-12">
                                    <textarea placeholder="Message" ></textarea>
                                </div>
                                
                                <div class="form-group col-lg-12 col-md-12 col-sm-12">
                                    <button class="theme-btn btn-style-four" type="submit" name="submit-form">Submit Request</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!--Content Column-->
            <div class="content-column col-lg-6 col-md-12 col-sm-12">
              <div class="inner-column">
                  <div class="sec-title light">
                      <div class="title">Get In Touch</div>
                        <h2>Request a Free Consultation</h2>
                    </div>
                    <h3>20 Years combined Experience <br> in Financial Accounting <span class="theme_color">Services</span></h3>
                    <div class="text">Send us a email and we'll get in touch shortly, or phone between 9:00am and 5:00pm Monday to Friday - We would be delighted to speak.</div>
                    <a href="/contact-us?subject=Request a Free Consultation" class="theme-btn btn-style-five">Contact Now</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--End Consulting Section-->

<!--News Section-->
<section class="news-section">
  <div class="auto-container">
      <div class="sec-title">
          <div class="title">Latest News</div>
            <h2>Our Latest Articles & News</h2>
        </div>
        <div class="row clearfix">
          
            <ng-container *ngFor="let item of news">
            <!--News Block-->
            <!-- <div class="news-block col-lg-4 col-md-6 col-sm-12"  >
              <div class="inner-box">
                  <div class="image">
                      <img [src]="item.postImage" alt="" />
                        <a href="assets/images/resource/news-1.jpg" class="overlay-box" data-fancybox="gallery" data-caption="">
                          <span class="icon flaticon-full-screen"></span>
                        </a>
                        <div class="post-date"><span>{{item.day}}</span>{{item.month}}</div>
                    </div>
                    <div class="lower-content">
                      <h3><a href="{{item.externalLink}}">{{item.titlet}}</a></h3>
                        <div class="text" [innerHTML]="item.content">Lorem ipsum dolor sit amet, ei qui essent pericula convenire phaedrum lorem ipsum dolor sit amet, ei qui essent pericula convenire.<a href={{item.externalLink}}>Read More <span class="icon fa fa-angle-double-right"></span></a></div>
                        
                    </div>
                </div>
            </div> -->
                       <!--News Block-->
                       <div class="news-block col-lg-4 col-md-6 col-sm-12">
                        <div class="inner-box">
                            <div class="image">
                                <img [src]="item.postImage" alt="" />
                                <a href="{{item.postImage}}" class="overlay-box" data-fancybox="gallery" data-caption="">
                                    <span class="icon flaticon-full-screen"></span>
                                </a>
                                <div class="post-date"><span>{{item.day}}</span><br/>{{item.month}}</div>
                            </div>
                            <div class="lower-content">
                                <h3><a href="{{item.externalLink}}">{{item.title}}</a></h3>
                                <div class="text">{{item.preview}}<a href={{item.externalLink}}> Read More <span class="icon fa fa-angle-double-right"></span></a></div>
                               
                            </div>
                        </div>
                    </div>
        </ng-container>
            
        </div>
    </div>
</section>
<!--End News Section-->
<app-contact-banner></app-contact-banner>

