<section class="page-title" style="background-image:url(assets/images/background/11.jpg)">
    <div class="auto-container">
        <h1>Bookkeeping</h1>
        <ul class="page-breadcrumb">
            <li><a href="index.html">Home</a></li>
            <li>Our Services</li>
        </ul>
    </div>
</section>
<!--Sidebar Page Container-->
<div class="sidebar-page-container">
    <div class="auto-container">
        <div class="row clearfix">

            <!--Sidebar Side-->
            <div class="sidebar-side col-lg-4 col-md-4 col-sm-12">
                <aside class="sidebar default-sidebar no-padd">
                    <!--Blog Category Widget-->
                    <div class="sidebar-widget sidebar-blog-category">
                        <ul class="blog-cat">
                            <li [routerLinkActive]="['active']"><a [routerLink]="['/our-services/accounting']"><span
                                        class="icon flaticon-employee"></span> Accounting Services</a></li>
                            <li [routerLinkActive]="['active']"><a [routerLink]="['/our-services/bookkeeping']"><span
                                        class="icon flaticon-money"></span> Bookkeeping Services</a></li>
                            <li [routerLinkActive]="['active']"><a [routerLink]="['/our-services/taxation']"><span
                                        class="icon flaticon-diagram"></span> Taxation Services</a></li>
                            <li [routerLinkActive]="['active']"><a [routerLink]="['/our-services/payroll']"><span
                                        class="icon flaticon-pen"></span> Payroll Services</a></li>
                            <li [routerLinkActive]="['active']"><a
                                    [routerLink]="['/our-services/compensation-fund']"><span
                                        class="icon flaticon-meeting"></span> Compensation Fund</a></li>
                            <li [routerLinkActive]="['active']"><a [routerLink]="['/our-services/secretarial']"><span
                                        class="icon flaticon-bank-1"></span> Secretarial Services</a></li>
                        </ul>
                    </div>

                    <!--Help Widget-->
                    <div class="sidebar-widget help-widget">
                        <div class="widget-inner">
                            <h2>Need Help ?</h2>
                            <div class="text">Please feel free to contact us. We will get back to you within 1-2
                                business days. Or just call us now</div>
                            <ul class="help-list">
                                <li><span class="icon fa fa-phone"></span>+27 64 084 6775</li>
                                <li><span class="icon fa fa-envelope-o"></span>info@inertiaacc.co.za</li>
                            </ul>
                        </div>
                    </div>
                    <!--Brochure Widget-->
                    <div class="sidebar-widget brochure-widget">
                        <div class="widget-inner">
                            <div class="title">Download Our</div>
                            <h2>Company Profile</h2>
                            <div class="here"></div>
                            <a href="/assets/docs/company-profile.pdf" class="download-btn">Download</a>
                        </div>
                    </div>
                </aside>
            </div>
            <!--Content Side-->
            <div class="content-side col-lg-8 col-md-8 col-sm-12">
                <div class="services-single">
                    <div class="inner-box">
                        <div class="image">
                            <img src="assets/images/services/2.jpeg" alt="" />
                        </div>
                        <h2>Accounting & Bookkeeping</h2>
                        <div class="text">
                            <p>Our Accounting services ensure compliance with International Financial Reporting
                                Standards (IFRS) and they comprise of the following:</p>
                            <ul class="services-list">
                                <li>Processing of accounting records</li>
                                <li>Preparation of monthly and/or quarterly management accounts</li>
                                <li>Preparation of full set of interim and annual financial statements</li>
                                <li>Technical advice on complex accounting issues</li>
                                <li>Budget preparation and variance analysis</li>
                                <li>Creditors and Debtors Management</li>
                                <li>Cash flow projections</li>
                                <li>Sage One Advisory</li>
                                <li>Bank Reconciliation</li>
                                <li>VAT Reconciliation</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--End Sidebar Page Container-->