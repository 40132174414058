    <!--Page Title-->
    <section class="page-title" style="background-image: url(assets/images/background/accounting4.jpg);background-size: cover;background-position-y: center;">
    	<div class="auto-container">
        	<h1>Services</h1>
            <ul class="page-breadcrumb">
            	<li><a [routerLink]="['/home']">Home</a></li>
                <li>Services</li>
            </ul>
        </div>
    </section>
    <!--End Page Title-->
    
    <!--Services Section-->
    <section class="services-section">
    	<div class="auto-container">
        	<!--Sec Title-->
        	<div class="sec-title">
            	<div class="title">WHAT WE DO</div>
                <h2>Our Main Services</h2>
            </div>
            <div class="row clearfix">
            	
                <!--Services Block Two-->
                <div class="services-block-two col-lg-4 col-md-6 col-sm-12">
                	<div class="inner-box">
                    	<div class="image">
                        	<img src="assets/images/services/1.jpeg" alt="" />
                            <div class="icon-box">
                            	<span class="icon flaticon-employee"></span>
                            </div>
                            <div class="overlay-box">
                            	<div class="overlay-inner">
                                	<div class="content">
                                        <div class="overlay-icon">
                                            <span class="icon flaticon-employee"></span>
                                        </div>
                                        <div class="text">Processing of accounting records & Preparation of monthly and/or quarterly accounts</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="lower-box">
                        	<h3><a [routerLink]="['/our-services/accounting']">Accounting Services</a></h3>
                            <a [routerLink]="['/our-services/accounting']" class="arrow-box"><span class="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                </div>
                
                <!--Services Block Two-->
                <div class="services-block-two col-lg-4 col-md-6 col-sm-12">
                	<div class="inner-box">
                    	<div class="image">
                        	<img src="assets/images/services/2.jpeg" alt="" />
                            <div class="icon-box">
                            	<span class="icon flaticon-money"></span>
                            </div>
                            <div class="overlay-box">
                            	<div class="overlay-inner">
                                	<div class="content">
                                        <div class="overlay-icon">
                                            <span class="icon flaticon-money"></span>
                                        </div>
                                        <div class="text">Reconcile bank statements to internal accounts and help out during an internal or IRS audit.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="lower-box">
                        	<h3><a [routerLink]="['/our-services/bookkeeping']">Bookkeeping Services</a></h3>
                            <a [routerLink]="['/our-services/bookkeeping']" class="arrow-box"><span class="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                </div>
                
                <!--Services Block Two-->
                <div class="services-block-two col-lg-4 col-md-6 col-sm-12">
                	<div class="inner-box">
                    	<div class="image">
                        	<img src="assets/images/services/3.jpg" alt="" />
                            <div class="icon-box">
                            	<span class="icon flaticon-diagram"></span>
                            </div>
                            <div class="overlay-box">
                            	<div class="overlay-inner">
                                	<div class="content">
                                        <div class="overlay-icon">
                                            <span class="icon flaticon-diagram"></span>
                                        </div>
                                        <div class="text">Personal and Business Tax Planning, Submission of returns with SARS</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="lower-box">
                        	<h3><a [routerLink]="['/our-services/taxation']">Taxation Services</a></h3>
                            <a [routerLink]="['/our-services/taxation']" class="arrow-box"><span class="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                </div>
                
                <!--Services Block Two-->
                <div class="services-block-two col-lg-4 col-md-6 col-sm-12">
                	<div class="inner-box">
                    	<div class="image">
                        	<img src="assets/images/services/4.jpg" alt="" />
                            <div class="icon-box">
                            	<span class="icon flaticon-pen"></span>
                            </div>
                            <div class="overlay-box">
                            	<div class="overlay-inner">
                                	<div class="content">
                                        <div class="overlay-icon">
                                            <span class="icon flaticon-pen"></span>
                                        </div>
                                        <div class="text">We offer a comprehensive weekly, bi-monthly and monthly payroll services</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="lower-box">
                        	<h3><a [routerLink]="['/our-services/payroll']">Payroll Services</a></h3>
                            <a [routerLink]="['/our-services/payroll']" class="arrow-box"><span class="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                </div>
                
                <!--Services Block Two-->
                <div class="services-block-two col-lg-4 col-md-6 col-sm-12">
                	<div class="inner-box">
                    	<div class="image">
                        	<img src="assets/images/services/5.jpg" alt="" />
                            <div class="icon-box">
                            	<span class="icon flaticon-meeting"></span>
                            </div>
                            <div class="overlay-box">
                            	<div class="overlay-inner">
                                	<div class="content">
                                        <div class="overlay-icon">
                                            <span class="icon flaticon-meeting"></span>
                                        </div>
                                        <div class="text">You can transform your business to a asset coaching programs</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="lower-box">
                        	<h3><a [routerLink]="['/our-services/compensation-fund']">Compensation Fund</a></h3>
                            <a [routerLink]="['/our-services/compensation-fund']" class="arrow-box"><span class="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                </div>
                
                <!--Services Block Two-->
                <div class="services-block-two col-lg-4 col-md-6 col-sm-12">
                	<div class="inner-box">
                    	<div class="image">
                        	<img src="assets/images/services/6.jpg" alt="" />
                            <div class="icon-box">
                            	<span class="icon flaticon-bank"></span>
                            </div>
                            <div class="overlay-box">
                            	<div class="overlay-inner">
                                	<div class="content">
                                        <div class="overlay-icon">
                                            <span class="icon flaticon-bank"></span>
                                        </div>
                                        <div class="text">Registrations of new legal entities (Pty & NPC), Annual returns at CIPC & BBBEE Certificates</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="lower-box">
                        	<h3><a [routerLink]="['/our-services/secretarial']">Secretarial Services</a></h3>
                            <a [routerLink]="['/our-services/secretarial']" class="arrow-box"><span class="fa fa-angle-right"></span></a>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    <!--End Services Section-->
    
    <!--Approach Section-->
    <section class="approach-section" style="background-image:url(assets/images/background/2.jpg)">
    	       
        <!--Steps Section-->
        <div class="steps-section">
        	<div class="auto-container">
            	<div class="sec-title">
                	<div class="title">How We Work</div>
                    <h2>4 Steps To Make Successful Business</h2>
                </div>
                <div class="row clearfix">
                	
                    <!--Step Block-->
                    <div class="step-block col-lg-3 col-md-6 col-sm-12">
                    	<div class="inner-box">
                        	<div class="number"><span>1 -</span>  Analize</div>
                            <div class="text">We utilizes creative and customized methods that tailor our work to the client environment to maximize results.</div>
                        </div>
                    </div>
                    
                    <!--Step Block-->
                    <div class="step-block col-lg-3 col-md-6 col-sm-12">
                    	<div class="inner-box">
                        	<div class="number"><span>2 -</span>  Advise</div>
                            <div class="text">Find out when where business needs to go and how to get there – real progress is made.</div>
                        </div>
                    </div>
                    
                    <!--Step Block-->
                    <div class="step-block col-lg-3 col-md-6 col-sm-12">
                    	<div class="inner-box">
                        	<div class="number"><span>3 -</span>  Strategy</div>
                            <div class="text">We deliver business results via hands-on execution and leading teams through complex change.</div>
                        </div>
                    </div>
                    
                    <!--Step Block-->
                    <div class="step-block col-lg-3 col-md-6 col-sm-12">
                    	<div class="inner-box">
                        	<div class="number"><span>4 -</span>  Result</div>
                            <div class="text">We provide valuable guidance and support in the development, and you run a successful business.</div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
      </section>
    <!--End Approach Section-->
    <app-contact-banner></app-contact-banner>