<!--Page Title-->
<section class="page-title"
    style="background-image: url(assets/images/background/accounting4.jpg);background-size: cover;background-position-y: center;">
    <div class="auto-container">
        <h1>About Us</h1>
        <ul class="page-breadcrumb">
            <li><a [routerLink]="['/home']">Home</a></li>
            <li>About Us</li>
        </ul>
    </div>
</section>
<!--End Page Title-->

<!--Welcome Section-->
<section class="welcome-section">
    <div class="auto-container">
        <div class="row clearfix">

            <!--Content Column-->
            <div class="content-column col-lg-7 col-md-12 col-sm-12">
                <div class="inner-column">
                    <!--Sec Title-->
                    <div class="sec-title">
                        <div class="title">Who We Are</div>
                        <h2>INERTIA ACCOUNTING SERVICES</h2>
                    </div>
                    <div class="text">We work to bring the figures to life and help our clients understand exactly what
                        they mean and what they implicate for their business. To ensure we tailor our approach to your
                        business we always start by gaining a thorough understanding of you and your business.</div>
                    <div class="clearfix">
                        <div class="pull-left">
                            <a [routerLink]="['/contact-us']" class="theme-btn btn-style-four">Contact Us</a>
                        </div>
                        <div class="pull-right">
                            <div class="phone"><span class="icon fa fa-phone"></span> +27 13 110 4907</div>
                        </div>
                    </div>
                </div>
            </div>

            <!--Image Column-->
            <div class="image-column col-lg-5 col-md-12 col-sm-12">
                <div class="inner-column">
                    <div class="image">
                        <img src="assets/images/background/about-us.PNG" alt="" />

                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<!--End Welcome Section-->

<!--Experts Section-->
<section class="experts-section">
    <div class="auto-container">
        <div class="row clearfix">
            <!--Content Column-->
            <div class="stats-column col-lg-6 col-md-12 col-sm-12">
                <div class="inner-column">
                    <!--Sec Title-->
                    <div class="sec-title">
                        <div class="title">what drives us</div>
                        <h2>Our Vision</h2>
                        <div class="text">To be the most trusted and respected accountng firm by providing innovative
                            and practical services that contribute to clients success in an exceptional team
                            environment.</div>
                    </div>

                    <div class="sec-title">
                        <h2>Our Mission</h2>
                        <div class="text">To provide the highest level of professional services to meet clients needs
                            through mutual financial success.</div>
                    </div>

                </div>
            </div>

            <div class="content-column col-lg-6 col-md-12 col-sm-12">
                <div class="inner-column">

                    <div class="sec-title">
                        <div class="title">what guides us</div>
                        <h2>Our Values</h2>
                        <div class="blocks-column col-lg-12 col-md-12 col-sm-12">
                            <div class="inner-column">
                                <div class="row clearfix">

                                    <!--Expert Block-->
                                    <div class="expert-block col-lg-6 col-md-6 col-sm-12">
                                        <br/>
                                        <div class="team-content">
                                            <h3><span class="text-default">Intergrity</span></h3>
                                            <h3><span class="text-default">Honesty</span></h3>
                                            <h3><span class="text-default">Timeliness</span></h3>
                                            <h3><span class="text-default">Efficiency</span></h3>
                                            <h3><span class="text-default">Respect for people</span></h3>
                                            </div>
                                       
                                    </div>

                                    <!--Expert Block-->
                                    <div class="expert-block col-lg-6 col-md-6 col-sm-12">
                                        <div class="inner-box">
                                            <div class="image">
                                                <a href="#"><img src="assets/images/resource/expert-1.jpg" alt=""></a>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </div>
    </div>
</section>
<!--End Experts Section-->


<!--Fluid Section Two-->
<section class="fluid-section-two">
    <div class="outer-container clearfix">
        <!--Image Column-->
        <div class="image-column" style="background-image:url(assets/images/background/meeting.jpeg);">
            <figure class="image-box"><img src="assets/images/background/meeting.jpeg" alt=""></figure>
        </div>
        <!--Content Column-->
        <div class="content-column">
            <div class="inner-column dark">
                <!--Sec Title-->
                <div class="sec-title dark">
                    <div class="title">why us</div>
                    <h2>Why Choose Us</h2>
                </div>
                <div class="team-content">
                    <h3>Systems and Processes that Improve Decision-making</h3>
                    <div class="text">Accounting is not just a means of recording a business’s financial history in an after-the-fact manner. Used properly, accurate and timely financial  data can help you make intelligent decisions that affect the bottom lines and shape your future. Our clients benefit from structured accounting systems and processes as well as our insightful and proactive approach to business and tax planning.</div>
                </div>
                <div class="team-content">
                    <h3>Cultivate Long-Term Relationships With Our Clients</h3>
                    <div class="text">To us, our clients are not just numbers, they're people that we know and work hard for, regardless of the size of their business. When you become our client, we spend time learning all we can about you and your business. As your business grows and evolves, we'll continually adjust our services to keep pace with your needs and help you get to the next level.</div>
                </div>
                <div class="team-content">
                    <h3>Expertise that you can rely upon</h3>
                    <div class="text">We offer comprehensive accounting and consulting services for new and established businesses including financial statement preparation, business  consulting, payroll preparation, and bookkeeping, tax planning and preparation</div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--End Fluid Section Two-->
<app-contact-banner></app-contact-banner>