import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-book-keeping',
  templateUrl: './book-keeping.component.html',
  styleUrls: ['./book-keeping.component.css']
})
export class BookKeepingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
