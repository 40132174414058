import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private toastr: ToastrService, private sanitizer: DomSanitizer) {}

  showLoadingErrorMessage(topic: string, isCache?: boolean) {
    const message = `Error while loading <strong>${topic}${
      isCache ? '<i> due to cache</i>' : ''
    }</strong>.`;
    this.showMessage(message, 'error');
  }

  showMessage(
    message: string,
    messageType: 'error' | 'success',
    from = 'top',
    align = 'right'
  ) {
    const icon =
    messageType === 'success' ? 'check' : 'error';
    const rawHtmlMessage = `<span class="bx bx-${icon}" [data-notify]="icon"></span> ${message}`;
    const safeHtmlMessage = this.sanitizer.bypassSecurityTrustHtml(
      rawHtmlMessage
    );

    this.toastr.info(safeHtmlMessage as string, '', {
      timeOut: 16000,
      easeTime: 250,
      enableHtml: true,
      closeButton: true,
      positionClass: 'toast-' + from + '-' + align,
      toastClass: `alert alert-${
        messageType === 'error' ? 'danger' : 'info'
      } alert-with-icon`,
    });
  }

  showAppMessage(
    message: string,
    messageType: 'error' | 'success',
    from = 'top',
    align = 'center'
  ) {
    const icon =
      messageType === 'success' ? 'check' : 'error';
    const rawHtmlMessage = `<span class="bx bx-${icon}" [data-notify]="icon"></span> ${message}`;
    const safeHtmlMessage = this.sanitizer.bypassSecurityTrustHtml(
      rawHtmlMessage
    );

    this.toastr.info(safeHtmlMessage as string, '', {
      enableHtml: true,
      tapToDismiss: false,
      disableTimeOut: true,
      positionClass: 'toast-' + from + '-' + align,
      toastClass: `alert alert-${
        messageType === 'error' ? 'danger' : 'info'
      } alert-with-icon`,
    });
  }

  hideAppMessage() {
    this.toastr.clear();
  }

  public showErrorMessage(message: string, title:string): void {
    this.toastr.error(message,title);
  }

  public showSuccessMessage(message: string, title:string): void {
    this.toastr.success(message, title);
  }
}
