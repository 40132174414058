<section class="section features-area features-area-1 ptb-60 bg-gray">
    <div class="container">
        <div class="row mb-30">
            <div class="col-lg-7 col-md-8 col-sm-10 col-xs-12 col-xs-center t-center mb-40">
                <h6 class="section-subtitle mb-10 t-uppercase color-mid">OUR FEATURES</h6>
                <h2 class="section-title mb-20 font-22 t-uppercase">WHY CHOOSE US</h2>
                <div class="line"></div>
            </div>
        </div>
        <div class="row row-tb-15 clearfix">
            
            <!--Column-->
            <div class="col-md-4 col-sm-6 col-xs-12 column">
                <article class="feature-single">
                    <div class="feature-icon">
                        <i class="flaticon-transport-with-arm-and-scoop"></i>
                    </div>
                    <div class="feature-content">
                        <h4 class="mb-10">We’re Creative</h4>
                        <p class="color-mid">Proin nec lacus lacus. Sed vitae consectetur est, et mattis felis. Fusce lacinia nibh vitae fermentum finibus.</p>
                    </div>
                </article>
            </div>
            
            <!--Column-->
            <div class="col-md-4 col-sm-6 col-xs-12 column">
                <article class="feature-single">
                    <div class="feature-icon">
                        <i class="flaticon-architecture-crane-tool"></i>
                    </div>
                    <div class="feature-content">
                        <h4 class="mb-10">Excellent Services</h4>
                        <p class="color-mid">Proin nec lacus lacus. Sed vitae consectetur est, et mattis felis. Fusce lacinia nibh vitae fermentum finibus.</p>
                    </div>
                </article>
            </div>
            
            <!--Column-->
            <div class="col-md-4 col-sm-6 col-xs-12 column">
                <article class="feature-single">
                    <div class="feature-icon">
                        <i class="flaticon-constructor-with-hard-hat-protection-on-his-head"></i>
                    </div>
                    <div class="feature-content">
                        <h4 class="mb-10">We’re Professional</h4>
                        <p class="color-mid">Proin nec lacus lacus. Sed vitae consectetur est, et mattis felis. Fusce lacinia nibh vitae fermentum finibus.</p>
                    </div>
                </article>
            </div>
            
            <!--Column-->
            <div class="col-md-4 col-sm-6 col-xs-12 column">
                <article class="feature-single">
                    <div class="feature-icon">
                        <i class="flaticon-machine"></i>
                    </div>
                    <div class="feature-content">
                        <h4 class="mb-10">We’re Passionate</h4>
                        <p class="color-mid">Proin nec lacus lacus. Sed vitae consectetur est, et mattis felis. Fusce lacinia nibh vitae fermentum finibus.</p>
                    </div>
                </article>
            </div>
            
            <!--Column-->
            <div class="col-md-4 col-sm-6 col-xs-12 column">
                <article class="feature-single">
                    <div class="feature-icon">
                        <i class="flaticon-truck-transport-with-construction-materials"></i>
                    </div>
                    <div class="feature-content">
                        <h4 class="mb-10">Fast Fluid Service</h4>
                        <p class="color-mid">Proin nec lacus lacus. Sed vitae consectetur est, et mattis felis. Fusce lacinia nibh vitae fermentum finibus.</p>
                    </div>
                </article>
            </div> 

            <!--Column-->
            <div class="col-md-4 col-sm-6 col-xs-12 column">
                <article class="feature-single">
                    <div class="feature-icon">
                        <i class="flaticon-wrench-with-adjusting-system-for-different-nuts-sizes"></i>
                    </div>
                    <div class="feature-content">
                        <h4 class="mb-10">Excellent Services</h4>
                        <p class="color-mid">Proin nec lacus lacus. Sed vitae consectetur est, et mattis felis. Fusce lacinia nibh vitae fermentum finibus.</p>
                    </div>
                </article>
            </div>
            
        </div>
    </div>
</section>