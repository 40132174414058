<section class="section portfolio-area portfolio-area-1 pt-40 pb-80">
    <div class="container">
        <div class="row mb-30">
            <div class="col-lg-7 col-md-8 col-sm-10 col-xs-12 col-xs-center t-center mb-40">
                <h6 class="section-subtitle mb-10 t-uppercase color-mid">Project management</h6>
                <h2 class="section-title mb-20 font-22 t-uppercase">Featured projects</h2>
                <div class="line"></div>
            </div>
        </div>
        <div class="portfolio-filter">
            <div>
                <a href="#" class="filter" data-filter="all">All</a>
            </div>
            <div>
                <a href="#" class="filter" data-filter=".building">Building</a>
            </div>
            <div>
                <a href="#" class="filter" data-filter=".architecture">Architecture</a>
            </div>
            <div>
                <a href="#" class="filter" data-filter=".construction">Construction</a>
            </div>
            <div>
                <a href="#" class="filter" data-filter=".repairing">Repairing</a>
            </div>
        </div>
        <div class="portfolio-wrapper row">
            <div class="portfolio-single mix col-xs-12 col-sm-6 col-md-4 construction repairing architecture">
                <img src="assets/images/portfolio/02.jpg" alt="">
                <div class="portfolio-hover">
                    <div class="inner-hover">
                        <h2 class="h5 t-uppercase pb-10 mb-5"><a href="single-project.html">Project Title</a></h2>
                        <span>Building</span>
                    </div>
                </div>
            </div>
            <div class="portfolio-single mix col-xs-12 col-sm-6 col-md-4 architecture building">
                <img src="assets/images/portfolio/03.jpg" alt="">
                <div class="portfolio-hover">
                    <div class="inner-hover">
                        <h2 class="h5 t-uppercase pb-10 mb-5"><a href="single-project.html">Project Title</a></h2>
                        <span>Building</span>
                    </div>
                </div>
            </div>
            <div class="portfolio-single mix col-xs-12 col-sm-6 col-md-4 repairing architecture">
                <img src="assets/images/portfolio/04.jpg" alt="">
                <div class="portfolio-hover">
                    <div class="inner-hover">
                        <h2 class="h5 t-uppercase pb-10 mb-5"><a href="single-project.html">Project Title</a></h2>
                        <span>Building</span>
                    </div>
                </div>
            </div>
            <div class="portfolio-single mix col-xs-12 col-sm-6 col-md-4 architecture repairing construction">
                <img src="assets/images/portfolio/06.jpg" alt="">
                <div class="portfolio-hover">
                    <div class="inner-hover">
                        <h2 class="h5 t-uppercase pb-10 mb-5"><a href="single-project.html">Project Title</a></h2>
                        <span>Building</span>
                    </div>
                </div>
            </div>
            <div class="portfolio-single mix col-xs-12 col-sm-6 col-md-4 repairing building architecture">
                <img src="assets/images/portfolio/07.jpg" alt="">
                <div class="portfolio-hover">
                    <div class="inner-hover">
                        <h2 class="h5 t-uppercase pb-10 mb-5"><a href="single-project.html">Project Title</a></h2>
                        <span>Building</span>
                    </div>
                </div>
            </div>
            <div class="portfolio-single mix col-xs-12 col-sm-6 col-md-4 repairing building construction">
                <img src="assets/images/portfolio/08.jpg" alt="">
                <div class="portfolio-hover">
                    <div class="inner-hover">
                        <h2 class="h5 t-uppercase pb-10 mb-5"><a href="single-project.html">Project Title</a></h2>
                        <span>Building</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
