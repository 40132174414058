<!--Page Title-->
<section class="page-title"
    style="background-image: url(assets/images/background/accounting4.jpg);background-size: cover;background-position-y: center;">
    <div class="auto-container">
        <h1>Contact Us</h1>
        <ul class="page-breadcrumb">
            <li><a [routerLink]="['/home']">Home</a></li>
            <li>Contact Us</li>
        </ul>
    </div>
</section>
<!--End Page Title-->

<!--Default Form Section-->
<section class="default-form-section">
    <div class="auto-container">
        <!--Title Box-->
        <div class="title-box">
            <div class="title">Write a Message</div>
            <h2>Have Any Questions?</h2>
            <div class="text">Thank you very much for your interest in our company and our services and if you have any
                questions, please send us a message below!</div>
        </div>

        <!--Default Form-->
        <div class="default-form style-two contact-form">
            <form id="contact-form">
                <div class="row clearfix">
                    <div class="form-group col-lg-6 col-md-6 col-sm-12">
                        <input type="text" placeholder="First Name" name="firstName"
                            [(ngModel)]="contactModel.firstName" required>
                    </div>
                    <div class="form-group col-lg-6 col-md-6 col-sm-12">
                        <input type="text" placeholder="Surname" name="lastName" [(ngModel)]="contactModel.lastName"
                            required>
                    </div>
                    <div class="form-group col-lg-6 col-md-6 col-sm-12">
                        <input type="text" placeholder="Phone" name="phone" [(ngModel)]="contactModel.phone"
                            required>
                    </div>
                    <div class="form-group col-lg-6 col-md-6 col-sm-12">
                        <input type="email" name="email" [(ngModel)]="contactModel.email" placeholder="Your Email"
                            required>
                    </div>
                    <div class="form-group col-lg-12 col-md-12 col-sm-12">
                        <input type="text" name="subject" [(ngModel)]="contactModel.subject"
                            placeholder="Your Subject">
                    </div>
                    <div class="form-group col-lg-12 col-md-12 col-sm-12">
                        <textarea name="message" [(ngModel)]="contactModel.message" placeholder="Your Massage"></textarea>
                    </div>

                    <div class="form-group text-center col-lg-12 col-md-12 col-sm-12">
                        <button type="button" class="theme-btn message-btn" (click)="submitRequest()">Send
                            Massage</button>
                    </div>
                </div>
            </form>
        </div>
        <!--End Default Form-->


    </div>
</section>
<!--End Default Form Section-->

<!--Contact Info Section-->
<section class="contact-info-section">
    <div class="auto-container">
        <div class="row clearfix">

            <div class="column col-lg-4 col-md-6 col-sm-12">
                <ul>
                    <li>
                        <span class="icon flaticon-placeholder"></span>
                        136, 35 Mandela St <br> Witbank 1039, South Africa
                    </li>
                </ul>
            </div>

            <div class="column col-lg-4 col-md-6 col-sm-12">
                <ul>
                    <li>
                        <span class="icon flaticon-technology-2"></span>
                        +27 13 110 4907 / +27 64 084 6775 <br> info&#64;inertiaacc.co.za
                    </li>
                </ul>
            </div>

            <div class="column col-lg-4 col-md-6 col-sm-12">
                <ul>
                    <li>
                        <span class="icon flaticon-clock-2"></span>
                        Mon - Fri 08:00am to 5:00pm <br> Sat 09:00am to 2:00pm
                    </li>
                </ul>
            </div>

        </div>
    </div>
</section>
<!--End Contact Info Section-->

<!--Map Section-->
<section class="contact-map-section">
    <!--Map Outer-->
    <div class="map-outer">
        <!--Map Canvas-->
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3589.517925712751!2d29.251622215023435!3d-25.88533918358082!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1eeaf28d156e30b3%3A0x692511a0a3ecfbf1!2s50%20Nita%20Ave%2C%20Del%20Judor%2C%20Emalahleni%2C%201034!5e0!3m2!1sen!2sza!4v1624648467504!5m2!1sen!2sza"
            height="500" allowfullscreen=""></iframe>
    </div>
</section>
<!--End Map Section-->